<template>
  <div class="container-fluid">
    <div v-if="loading" class="row">
      <div class="col-12">
        Loading...
        <p>{{ loadingMessage }}</p>
      </div>
    </div>
    <div v-else class="row">
      <form ref="reportSelector" class="col-12 mb-3">
        <div class="row">
          <div class="col-sm-6 col-md-4">
            <label for="select-report-set-report" class="form-label">Select a report</label>
            <select id="select-report-set-report" v-model="selectedReport" class="form-control">
              <option :value="null" disabled>
                Please select a report
              </option>
              <option v-for="report of availableReports" :key="report.report_slug" :value="report">
                {{ report.report_name }}
              </option>
            </select>
          </div>
        </div>
      </form>

      <form v-if="selectedReport && selectedReport.params" ref="reportParams" class="col-12 mb-3" @submit.prevent="generateReportPreview">
        <div class="row">
          <div v-for="param of selectedReport.params" :key="param.param_name" class="col-3 mb-3">
            <label class="form-label">{{ param.label || param.name }} <span v-if="param.required" class="text-danger">*</span></label>
            <MultiSelectInput v-if="param.type.startsWith('lookup')" 
              v-model="selectedReportParamValues[param.name].value" 
              col-style=" "
              :disabled="selectedReportParamValues[param.name].selectAllOverride"
              :options="lookups[param.name]"
              :multiple="param.type.includes('multiple')"
              :loading="searchLookupLoading[param.name]"
              :name="param.name"
              label-key="label"
              value-key="code"
              :rules="param.required ? 'required' : ''"
              :hide-label="true" />

              <template v-else-if="param.type === 'boolean'">
                  <div class="report-checkobox">
                    <input :id="param.name"
                      v-model="selectedReportParamValues[param.name].value"
                      :aria-labelledby="param.name"
                      type="checkbox" class="">
                    <label :for="param.name" class="">
                      <span>Yes</span>
                    </label>
                  </div>
            </template>

            <template v-else-if="param.type === 'month_year'">
              <VueDatePicker v-model="selectedReportParamValues[param.name].value"
                name="vue-date-picker"
                :disabled="selectedReportParamValues[param.name].selectAllOverride"
                :month-picker="param.type === 'month_year'"
                :auto-apply="true"
                :required="param.required" />
            </template>

            <template v-else-if="param.type === 'from_date'">
              <VueDatePicker v-model="selectedReportParamValues[param.name].value"
                name="vue-date-picker"
                :enable-time-picker="false"
                :min-date="minDate"
                :max-date="maxDate"
                :start-date="startDate"
                :disabled="selectedReportParamValues[param.name].selectAllOverride"
                :auto-apply="true"
                :required="param.required" 
                @cleared="handleFromDateCleared"
                @update:model-value="handleFromDateChange"
                />
            </template>

            <template v-else-if="param.type === 'to_date'">
              <VueDatePicker v-model="selectedReportParamValues[param.name].value"
                name="vue-date-picker"
                :enable-time-picker="false"
                :min-date="minDate"
                :max-date="maxDate"
                :start-date="startDate"
                :disabled="selectedReportParamValues[param.name].selectAllOverride"
                :auto-apply="true"
                :required="param.required" 
                @cleared="handleToDateCleared"
                @update:model-value="handleToDateChange"
              />
            </template>

            <input v-else
              v-model="selectedReportParamValues[param.name].value"
              :disabled="selectedReportParamValues[param.name].selectAllOverride"
              type="text" class="form-control"/>
          </div>

        </div>

        <div class="row">
          <div class="col-12">
            <button type="submit" class="btn btn-outline-success">
              Preview report!
            </button>
            <button type="button" class="btn btn-outline-info ml-4" @click.prevent="generateCSVFile">
              Download CSV
            </button>
          </div>
        </div>
      </form>

      <div class="col-12">
        <ReportTableComponent v-if="reportPreviewData" :report-data="reportPreviewData" />
      </div>
    </div>
  </div>
</template>


<script lang="js">
import beApiClient from "@/src/be-api-client";
import VueDatePicker from '@vuepic/vue-datepicker';
import ReportTableComponent from "@/src/components/rundown/ReportTableComponent.vue";
import MultiSelectInput from "@/src/components/shared/FormComponents/MultiSelectInput.vue";

export default {
  components: {
    VueDatePicker,
    ReportTableComponent,
    MultiSelectInput
  },
  props: {
    reportSetBaseUrl: {
      type: String,
      required: true
    },
    reportSetDefaultParams: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      loadingMessage: null,
      availableReports: [],
      lookupsToLoad: [],
      lookups: {},
      lookupsLoaded: 0,
      reportPreviewData: {},
      selectedReport: null,
      selectedReportParamValues: {},
      searchLookupLoading: {},
      selectedDate: null, // Bound to the date picker
      fromDate: null,
      toDate: null,
      startDate:null,
      minDate: null, 
      maxDate: null,
      report_configs: {}
    }
  },
  computed: {
    isFromDateWithinValidRange(){
      const minFromDateAllowed = new Date(this.toDate.toLocaleString('en-US', { timeZone: 'America/New_York' }));
      minFromDateAllowed.setMonth(this.toDate.getMonth() - parseInt(this.report_configs.date_range_in_months, 10));
      return this.fromDate < this.toDate && minFromDateAllowed <= this.fromDate;
    },
    isToDateWithinValidRange(){
      const maxToDateAllowed = new Date(this.fromDate.toLocaleString('en-US', { timeZone: 'America/New_York' }));
      maxToDateAllowed.setMonth(this.fromDate.getMonth() + parseInt(this.report_configs.date_range_in_months, 10));
      return this.toDate > this.fromDate &&  maxToDateAllowed >= this.toDate;
    },
    calculateMinDate() {
      const minAllowedDate = new Date();
      minAllowedDate.setMonth(minAllowedDate.getMonth() - parseInt(this.report_configs.min_date_offset_in_months, 10));
      return this.setMidnightET(minAllowedDate);
    },
    calculateMaxDate() {
      const today = new Date();
      return this.setEndOfDayET(today);
    },
    routes() {
      return {
        availableReports: `${this.reportSetBaseUrl}`,
        runReport: `${this.reportSetBaseUrl}/:report_name`,
        lookupToLoad: `${this.reportSetBaseUrl}/:report_name/lookups/:lookup_name`,
      }
    }
  },
  watch: {
    
    selectedReport(newVal) {
      if(!newVal) return;
      this.report_configs = newVal.report_configs;
      this.minDate = this.calculateMinDate, 
      this.maxDate = this.calculateMaxDate, 
      this.loading = true;
      this.loadingMessage = 'Getting Report Lookups';
      this.lookupsToLoad = [];
      this.lookups = [];
      this.reportPreviewData = [];
      this.selectedReportParamValues = {};
      this.selectedReport.params.forEach(param => {
        this.selectedReportParamValues[param.name] = {
          value: null,
          selectAllOverride: false
        }
        if(param.type.startsWith('lookup') && param.lookup_function) {
          this.lookupsToLoad.push(param);
        }
      });

      if(this.lookupsToLoad.length === 0) {
        this.selectDefaultParams();
        this.loading = false;
      } else {
        const _vm = this;
        this.lookupsToLoad.forEach(pl => {
          _vm.loadLookup(this.selectedReport.report_slug, pl.name).then(() => {
            if(Object.keys(_vm.lookups).length === this.lookupsToLoad.length) {
              this.selectDefaultParams();
              this.loading = false;
            }
          })
        });
      }
    }
  },
  mounted() {
    this.loadAvailableReports();
  },
  methods: {
    setMidnightET(date) {
      if (date===null) return null;
      const etDate = new Date(date.toLocaleString('en-US', { timeZone: 'America/New_York' }));
      etDate.setHours(0, 0, 0, 0);   
      return etDate;
    },
    setEndOfDayET(date) {
      if (date===null) return null;
      const etDate = new Date(date.toLocaleString('en-US', { timeZone: 'America/New_York' }));
      etDate.setHours(23, 11, 59, 59);   
      return etDate;
    },
    handleFromDateChange(newValue) { 
      if(newValue===null) return
      this.fromDate = this.setMidnightET(newValue);
      this.selectedReportParamValues['from_date'].value = this.fromDate
      this.startDate=new Date(this.fromDate.getFullYear(), this.fromDate.getMonth());
      if(!this.isToDateWithinValidRange){
        this.selectedReportParamValues['to_date'].value = null;
      }
    },
    handleToDateChange(newValue) {
      if(newValue===null) return; 
      this.toDate = this.setEndOfDayET(newValue);
      this.selectedReportParamValues['to_date'].value = this.toDate;
      this.startDate=new Date(this.toDate.getFullYear(), this.toDate.getMonth());
      if(!this.isFromDateWithinValidRange){
        this.selectedReportParamValues['from_date'].value = null;
      }
    },
    handleFromDateCleared(){
      this.fromDate=null;
      if (this.fromDate===null && this.toDate ===null){
        this.startDate = new Date();
      }      
    },
    handleToDateCleared(){
      this.toDate=null;
      if (this.fromDate===null && this.toDate ===null){
        this.startDate = new Date();
      }      
    },    
    loadAvailableReports() {
      this.loading = true;
      this.loadingMessage = 'Getting Reports';
      beApiClient.get(this.routes.availableReports).then((response) => {
        this.availableReports = response.data.available_reports
        this.selectDefaultReport();
        this.loading = false;
      })
    },
    searchLookup(evt, param) {
      // If we don't have a searchable lookup just pass
      if(!param.type.includes('lookup_search')) return;

      // beApiClient
      this.searchLookupLoading[param.name] = true;
      this.loadLookup(this.selectedReport.report_slug, param.name, evt).then(() => {
        this.searchLookupLoading[param.name] = false;
      })
    },
    loadLookup: async function(report_slug, lookup_name, search) {
      const route = [
        this.reportSetBaseUrl,
        report_slug,
        'lookups',
        lookup_name
      ].join('/');

      const response = await beApiClient.get(route, { params: { q: search }});
      const lookup = response.data;
      this.lookups[lookup.name] = lookup.data;
    },
    parseReportParams() {
      if(!this.selectedReport) return false;

      const paramValues = {};
      let validated = true;
      Object.keys(this.selectedReportParamValues).forEach(paramName => {
        const param = this.selectedReport.params.find((p) => p.name === paramName);
        if(!validated || !param) {
          validated = false;
          return;
        }

        if(this.selectedReportParamValues[paramName].selectAllOverride) {
          paramValues[param.name] = this.lookups[paramName].map((s) => s);
        }

        if(!this.selectedReportParamValues[paramName].value) {
          return;
        }

        if(param.type.includes('lookup')) {
          if(param.type.includes('multiple')) {
            paramValues[param.name] = this.selectedReportParamValues[paramName].value.map((s) => s);
          } else {
            paramValues[param.name] = this.selectedReportParamValues[paramName].value
          }
        } else if(param.type === 'month_year' && this.selectedReportParamValues[paramName].value) {
          // Need to handle the month year specially as the server expects months to start 1 but JS dates are 0 indexed
          paramValues[param.name] = {
            month: Number.isInteger(this.selectedReportParamValues[paramName]?.value.month)
                                    ? this.selectedReportParamValues[paramName].value.month + 1
                                    : this.selectedReportParamValues[paramName].value.month,
            year: this.selectedReportParamValues[paramName].value.year
          }
        } else {
          paramValues[param.name] = this.selectedReportParamValues[paramName].value;
        }
      })

      return validated ? paramValues : validated;
    },
    generateCSVFile() {
      this.loading = true;
      this.loadingMessage = 'Getting Report CSV';
      const paramValues = this.parseReportParams();
      if(!paramValues) return alert('Something went wrong when parsing the params. Please alert the devs');

      const url = [this.reportSetBaseUrl, this.selectedReport.report_slug, 'csv'].join('/');
      beApiClient.post(url, { param_values: paramValues }, { responseType: 'blob' } ).then(response => {
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(response.data);
        downloadLink.download = this.selectedReport.report_name + ' - ' + (new Date()).toISOString() + '.csv';
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        window.URL.revokeObjectURL(downloadLink.href);
        downloadLink.remove();
      }).catch((e) => {
        if(e.response.status !== 401) {
          alert('Error running report. Check all required params are selected!');
        }
        console.log(e);
      }).finally(() => {
        this.loading = false;
      });
    },
    generateReportPreview() {
      this.loading = true;
      this.loadingMessage = 'Getting Report Preview';
      const paramValues = this.parseReportParams();
      if(!paramValues) return alert('Something went wrong when parsing the params. Please alert the devs');

      const url = [this.reportSetBaseUrl, this.selectedReport.report_slug].join('/');
      beApiClient.post(url, { param_values: paramValues } ).then(response => {
        this.reportPreviewData = response.data.report;
      }).catch((e) => {
        if(e.response.status !== 401) {
          alert('Error running report. Check all required params are selected!');
        }
        console.log(e);
      }).finally(() => {
        this.loading = false;
      });
    },
    selectDefaultReport() {
      const defaultReportSlug = this.reportSetDefaultParams?.report_slug || null;
      const defaultReport = (this.availableReports || []).find((report) => { return report.report_slug == defaultReportSlug; });

      if (defaultReport) this.selectedReport = defaultReport;
    },
    selectDefaultParams() {
      const defaultParamKeys = Object.keys(this.reportSetDefaultParams || {}).filter((key) =>{ return key !== 'report_slug'; });
      defaultParamKeys.forEach((key) => {
        // Find the Default value specified in URL query params
        const rawDefaultValue = this.reportSetDefaultParams[key];
        if (!rawDefaultValue) return;

        // Parse the Default value which can be primative or object
        let parsedDefaultValue = null;
        try {
          parsedDefaultValue = JSON.parse(rawDefaultValue);
        } catch (error) {
          parsedDefaultValue = null;
        }

        // Find the Report Param object
        let paramObject = this.selectedReportParamValues[key];

        // Set the Value in the Report Param object
        if (typeof parsedDefaultValue === 'object' && !Array.isArray(parsedDefaultValue)) {
          if (!paramObject.value) paramObject.value = {};
          Object.assign(paramObject.value, parsedDefaultValue);
        } else {
          paramObject.value = parsedDefaultValue;
        }
      });
    },
  }
}
</script>